html {
  margin: 0;
  padding: 0;
  touch-action: pan-x pan-y;
}

body {
  overflow: overlay;
}

@keyframes heartBeat {
  0% {
    opacity: 1;
  }
  14% {
    opacity: 0;
  }
  28% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
}

.animate__heartBeat {
  animation: heartBeat 1s forwards;
}
